import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import stakingPoolABI from './abi/StakingPool.json';
import IERC20ABI from './abi/IERC20.json'; // ABI for ERC20 token
import './App.css';
// import logo from './logo.png';

const STAKING_POOL_ADDRESS = '0x6dbf33fe29cf1275e8cbe0f3f3e8c7c0a026c0bd';
const STAKING_TOKEN_ADDRESS = '0x77138410b33baaf456f8cf7e3ee0e8dd7ce2e09e';

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [stakingPool, setStakingPool] = useState(null);
  const [stakingToken, setStakingToken] = useState(null);
  const [stakeAmount, setStakeAmount] = useState('');
  const [totalStaked, setTotalStaked] = useState(0);
  const [userStake, setUserStake] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [stakingTokenBalance, setStakingTokenBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);
  const [alert, setAlert] = useState({ type: '', message: '' });

  useEffect(() => {
    async function loadWeb3() {
      const provider = await detectEthereumProvider();
      if (provider) {
        const web3Instance = new Web3(provider);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.requestAccounts();
        setAccount(accounts[0]);

        const stakingPoolInstance = new web3Instance.eth.Contract(stakingPoolABI, STAKING_POOL_ADDRESS);
        setStakingPool(stakingPoolInstance);

        const stakingTokenInstance = new web3Instance.eth.Contract(IERC20ABI, STAKING_TOKEN_ADDRESS);
        setStakingToken(stakingTokenInstance);
      } else {
        console.error('Please install MetaMask!');
      }
    }

    loadWeb3();
  }, []);

  useEffect(() => {
    if (stakingPool && stakingToken && account) {
      loadPoolData();
      loadUserData();
      loadBalances();
      checkAllowance();
    }
  }, [stakingPool, stakingToken, account]);

  async function loadPoolData() {
    const totalStaked = await stakingPool.methods.totalStaked().call();
    setTotalStaked(totalStaked);
  }

  async function loadUserData() {
    const userStake = await stakingPool.methods.stakes(account).call();
    const rewards = await stakingPool.methods.rewards(account).call();
    setUserStake(userStake.amount);
    setRewards(rewards);
  }

  async function loadBalances() {
    const ethBalance = await web3.eth.getBalance(account);
    const stakingTokenBalance = await stakingToken.methods.balanceOf(account).call();
    setEthBalance(ethBalance);
    setStakingTokenBalance(stakingTokenBalance);
  }

  async function checkAllowance() {
    const allowance = await stakingToken.methods.allowance(account, STAKING_POOL_ADDRESS).call();
    setAllowance(allowance);
  }

  function truncateAddress(address) {
    if (!address) return '';
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  }

  async function handleApproveAndStake() {
    try {
      if (parseFloat(Web3.utils.fromWei(allowance, 'ether')) < parseFloat(stakeAmount)) {
        // If allowance is not enough, approve tokens first
        await stakingToken.methods.approve(STAKING_POOL_ADDRESS, Web3.utils.toWei(stakeAmount, 'ether')).send({ from: account });
        setAlert({ type: 'success', message: 'Approval successful! You can now stake.' });
        checkAllowance();
      }

      // Proceed with staking
      await stakingPool.methods.stake(Web3.utils.toWei(stakeAmount, 'ether')).send({ from: account });
      setAlert({ type: 'success', message: 'Stake successful!' });

    } catch (error) {
      setAlert({ type: 'error', message: 'Failed to stake. Please try again.' });
    }
    loadPoolData();
    loadUserData();
    loadBalances();
  }

  async function handleUnstake() {
    try {
      await stakingPool.methods.unstake().send({ from: account });
      setAlert({ type: 'success', message: 'Unstake successful!' });
    } catch (error) {
      setAlert({ type: 'error', message: 'Failed to unstake. Please try again.' });
    }
    loadPoolData();
    loadUserData();
    loadBalances();
  }

  async function handleClaimRewards() {
    try {
      await stakingPool.methods.claimReward().send({ from: account });
      setAlert({ type: 'success', message: 'Rewards claimed successfully!' });
    } catch (error) {
      setAlert({ type: 'error', message: 'Failed to claim rewards. Please try again.' });
    }
    loadPoolData();
    loadUserData();
    loadBalances();
  }

  function handleCloseAlert() {
    setAlert({ type: '', message: '' });
  }

  return (
    <div className="container">
      <img src="/logo.png" alt="Logo" className="logo" />

      <div className="card card-info">
        <h1>Staking Pool</h1>
        <p><strong>Connected Account:</strong> {truncateAddress(account)}</p>
        <p><strong>ETH Balance:</strong> {Web3.utils.fromWei(ethBalance, 'ether')} ETH</p>
        <p><strong>Staking Token Balance:</strong> {Web3.utils.fromWei(stakingTokenBalance, 'ether')} Tokens</p>
        <p><strong>Total Staked:</strong> {Web3.utils.fromWei(totalStaked.toString(), 'ether')} Tokens</p>
        <p><strong>Your Staked Amount:</strong> {Web3.utils.fromWei(userStake.toString(), 'ether')} Tokens</p>
        <p><strong>Your Rewards:</strong> {Web3.utils.fromWei(rewards.toString(), 'ether')} Tokens</p>
        <p><strong>Approved Amount:</strong> {Web3.utils.fromWei(allowance.toString(), 'ether')} Tokens</p>
      </div>

      {alert.message && (
        <div className={`alert ${alert.type}`}>
          {alert.message}
          <button className="close-btn" onClick={handleCloseAlert}>×</button>
        </div>
      )}

      <div className="card card-actions">
        <input
          type="number"
          placeholder="Stake Amount"
          value={stakeAmount}
          onChange={(e) => setStakeAmount(e.target.value)}
        />

        <div className="button-container">
          <button onClick={handleApproveAndStake}>Approve & Stake</button>
          <button onClick={handleUnstake}>Unstake</button>
          <button onClick={handleClaimRewards}>Claim Rewards</button>
        </div>

      </div>

      <footer className="footer">
        <a
          href="https://www.dextools.io/app/en/ether/pair-explorer/0xa8213c550b84e70f33cfb64e09e51327fe11447c?t=1723485334678"
          target="_blank"
          rel="noopener noreferrer"
          className="dextools-link"
        >
          View on DEXTools
        </a>
      </footer>
    </div>
  );
}

export default App;